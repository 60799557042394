import React from 'react';
import { ArrowRightIcon } from '@heroicons/react/solid';

export default function Navbar() {
    return (
        <header className='bg-zinc-800 md:sticky top-0 z-10'>
            <div className='container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center'>
                <p className='title-font font-medium text-gray-300 mb-4 md:mb-0'>
                    <a href='#about' className='ml-3 text-xl'>
                        Kari Cheslock
                    </a>
                </p>
                <nav className='md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-1 md:border-gray-700 flex flex-wrap items-center text-base justify-center'>
                    <a href='#projects' className='mr-5 hover:text-white'>
                        Past Work
                    </a>
                    <a  href='#skills' className='mr-5 hover:text-white'>
                        Skills
                    </a>
                    <a href='#blogposts' className='mr-5 hover:text-white'>
                        Blog Posts
                    </a>
                </nav> 
                <a
                    href='#contact'
                    className='inline-flex items-center bg-green-600 border-0 py-1 px-3 focus:outline-none hover:bg-gray-700 rounded text-white mt-4 md:mt-0'
                >
                    Contact Me
                    <ArrowRightIcon className='w-4 h-4 ml-1' />
                </a>  
            </div>
        </header>
    )
}